import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squats 4-4-4-4\\@85% 1RM`}</p>
    <p>{`BB SLDL’s 4-4-4-4`}</p>
    <p>{`then,`}</p>
    <p>{`10-Pistols (per leg)`}</p>
    <p>{`20-Double Unders`}</p>
    <p>{`5-Rope Climbs (15′)`}</p>
    <p>{`20-Back Squats (185/125)`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`4-Rope Climbs`}</p>
    <p>{`30-Step Ups (15/leg, 24/20″ box)`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`3-Rope Climbs`}</p>
    <p>{`40-Walking Lunge Steps (20/leg)`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`2-Rope Climbs`}</p>
    <p>{`50-Squats`}</p>
    <p>{`60-Double Unders`}</p>
    <p>{`1-Rope Climb`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Ville will be closed this Saturday for Thunder.  East will be
open and we’ll have classes at 9:00 & 10:00am (instead of the usual 10 &
11). `}</em></strong></p>
    <p>{`*`}<strong parentName="p">{`CrossFit kids will be cancelled this Saturday as well.`}</strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start April 29th.  For more info
email Eric at: Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      